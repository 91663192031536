<template>
  <Modal v-model="importBackupOrderModal" :title="$t('key1005732')" :transfer="false" :mask-closable="false"
    width="750" @on-visible-change="importBackupOrderChange">
    <Form ref="importBackupOrderForm" :model="importBackupOrderForm" :label-width="labelWidth" @submit.native.prevent>
      <Row align="middle" justify="space-between">
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
          <Form-item :label="$t('key1005733')" prop="ymsWarehouseId" class="required_style_label">
            <Select filterable class="width_300" v-model="importBackupOrderForm.ymsWarehouseId">
              <Option v-for="item in warehouseList" :key="item.ymsWarehouseId" :value="item.ymsWarehouseId">{{ item.name }}</Option>
            </Select>
          </Form-item>
        </Col>
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol" v-if="isWarehouseManagement === 'Y'">
          <Form-item :label="$t('key1003563')" prop="outboundType" class="required_style_label">
            <Select v-model="importBackupOrderForm.outboundType" filterable transfer class="width_300">
              <Option v-for="(item, index) in outboundTypeList" :value="item.value" :key="index">{{ item.name }}</Option>
            </Select>
          </Form-item>
        </Col>
        <Col span="24" class="no_margin_style mb10">
          <Form-item :label="$t('key1000247')" prop="file" class="required_style_label">
            <div class="flex align-items-center">
              <Upload
                :disabled="!importBackupOrderForm.ymsWarehouseId"
                style="display: inline-block"
                :headers="headObj"
                ref="uploadRef"
                name='files'
                :data="uploadData"
                :show-upload-list="false"
                :action="uploadAction"
                :before-upload="beforeHandleUpload"
                :on-success="uploadSuccess"
                :on-error="uploadError"
                :max-size="uploadFilesMaxSize"
                :format="importFormat">
                <Button class="mr10" :disabled="!importBackupOrderForm.ymsWarehouseId">
                  <i class="iconfont">&#xe663;</i>
                  {{ $t('key1000250') }}
                </Button>
              </Upload>
              <a class="ml40" @click="download">{{ $t('key1000251') }}</a>
            </div>
          </Form-item>
        </Col>
        <Col span="24" class="no_margin_style mb10" v-if="excleFile">
          <Form-item :label="$t('key1000248')">
            <span>{{ excleFile.name }}</span>
          </Form-item>
        </Col>
        <Col span="24" push="2">
          <div class="tips_styles">
            {{ $t('key1002343') }}<br/>
            {{ $t('key1005734') }}<br/>
            {{ $t('key1005735') }}<br/>
            {{ $t('key1005736') }}<br/>
            {{ $t('key1005737') }}
          </div>
        </Col>
      </Row>
    </Form>
    <template #footer>
      <Button @click="importBackupOrderModal = false">{{ $t('key1000098') }}</Button>
      <Button type="primary" @click="importBackupOrderBtn" :disabled="!importBackupOrderForm.ymsWarehouseId">{{ $t('key1000097') }}</Button>
    </template>
  </Modal>
</template>

<script>
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';
import {downloadTemplate} from "@/utils/common";

export default {
  name: 'importBackupOrderModal',
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      importBackupOrderModal: false,
      importBackupOrderForm: {
        ymsWarehouseId: null,
        outboundType: null
      },
      warehouseList: [],
      uploadData: {},
      uploadAction: api.post_packageInfo_excelImportStockOrder,
      importFormat: ['xls', 'xlsx'],
      excleFile: null,
      outboundTypeList: [
        {value: 2, name: alias47916751af154eb5b47cd5de9d34633d.t('key1001304')},
        {value: 3, name: alias47916751af154eb5b47cd5de9d34633d.t('key1001305')}
      ],
      formatError: false,
    }
  },
  computed: {
    // 供应商系统仓储管理模块是否开启
    isWarehouseManagement() {
      return this.$store.state.isWarehouseManagement;
    },
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    },
  },
  created() {

  },
  methods: {
    // 初始化数据
    initImportBackupOrderData() {
      this.getBelongingWarehouseData(this.userInfo.merchantId).then((data) => {
        if (data && data.length > 0) {
          this.warehouseList = data;
        }
      });
      this.importBackupOrderModal = true;
    },
    // 上传文件之前
    beforeHandleUpload(excleFile) {
      let obj = {};
      let v = this;
      const fileExtension = excleFile.name.split('.').pop().toLowerCase();
      if (!v.importFormat.includes(fileExtension)) {
        this.$Message.error(alias47916751af154eb5b47cd5de9d34633d.t('key1005278'));
        return false;
      } else {
        v.excleFile = excleFile;
        if (v.isWarehouseManagement === 'Y') {
          obj.outboundType = v.importBackupOrderForm.outboundType;
        } else {
          obj.outboundType = 2;
        }
        obj.ymsWarehouseId = v.importBackupOrderForm.ymsWarehouseId;
        v.uploadData = Object.assign({}, obj);
        return false;
      }
    },
    // 上传文件失败
    uploadError() {
      this.formatError = true;
      this.importBackupOrderModal = true;
      this.$Message.error(alias47916751af154eb5b47cd5de9d34633d.t('key1000259'));
      return false;
    },
    // 上传文件成功
    uploadSuccess(res, file, fileList) {
      let v = this;
      let code = res.code;
      if (code === 0) {
        v.importBackupOrderModal = false;
        v.$Message.success(alias47916751af154eb5b47cd5de9d34633d.t('key1000131'));
        setTimeout(() => {
          v.$emit('updateData', true);
        }, 1000);
      }
    },
    // 确定
    importBackupOrderBtn() {
      let v = this;
      if (v.importBackupOrderForm.ymsWarehouseId) {
        if (v.isWarehouseManagement === 'Y') {
          if (v.importBackupOrderForm.outboundType) {
            handleData();
          } else {
            v.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1005738'));
            return false;
          }
        } else {
          handleData();
        }
      } else {
        v.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1005739'));
        return false;
      }

      function handleData() {
        if (v.excleFile) {
          v.$refs.uploadRef.post(v.excleFile); // 手动上传
          if (!v.formatError) {
            setTimeout(() => {
              v.excleFile = null;
              v.importBackupOrderModal = false;
            }, 2000);
          }
        } else {
          v.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1000269'));
          return false;
        }
      }
    },
    // 模板下载
    download() {
      downloadTemplate('supplierStockOrderImportTemplate.xlsx');
    },
    // 监听弹窗
    importBackupOrderChange(value) {
      if (!value) {
        this.$refs['importBackupOrderForm'].resetFields();
        this.warehouseList = [];
        this.excleFile = null;
      }
    }
  },
};
</script>

<style lang="less" scoped>
.no_margin_style {
  /deep/ .ivu-form-item {
    margin: 0 !important;
  }
}

.tips_styles {
  color: #9a9797;
  margin: 12px 0 20px 0;
}
</style>
